@use "./config" as *;
html {
    @extend %w-100;
    &.no-scroll,
    &.no-scroll body {
        touch-action: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        @extend %hidden;
    }
}
[v-cloak] {
    visibility: hidden;
}
body {
    &.scroll-up {
        .site-header {
            @extend %bg-neutral-100;
        }
        &.homepage {
            .menu-wrap {
                top: 0;
            }
        }
        .menu-wrap {
            position: fixed;
            z-index: var(--z-header);
        }
    }
}
.d-none {
    display: none;
}
.text-capitalize {
    text-transform: capitalize;
}
.grecaptcha-badge {
    visibility: hidden;
}
.waf-component {
    padding-block: var(--space-4);
    margin-bottom: var(--space-4); // avoid magin collapse
}
.img-box {
    @extend %bg-shimmer-light;
    @extend %half-radius;
    @extend %hidden;
}
img {
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    @extend %w-100;
    &[src=""] {
        visibility: hidden;
    }
}
[class^="img-"] {
    @extend %relative;
    img {
        @extend %h-100;
        @extend %position-t-l;
        &[alt] {
            font-size: 1.2rem;
            @extend %neutral-70;
        }
    }
}
@each $width,
$height in $aspect-ratio {
    .img-#{$width}by#{$height} .img-box {
        padding-bottom: calc(($height / $width) * 100%);
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend %m-0;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
.desktop {
    @extend %d-none;
}
.mobile {
    @extend %d-block;
}
@each $width in $flex-width {
    .m-w-#{$width} {
        width: calc($width * 1%);
        &-gap {
            width: calc((#{$width} - var(--space-1)) * 1%);
        }
    }
}
//project css start
/* web setup css done */
/* project common css start */
// .main-wrap {
//     padding-top: var(--header-height);
// }
// project layout wrapper style
.layout-wrapper {
    max-width: var(--container-max-width);
    width: 100%;
    margin-inline: auto;
}
// dropdown css starts
.form-select-box {
    @extend %mt-4;
    @extend %relative;
    .selected-title {
        height: 4.8rem;
        @include border(1, neutral-5, 10, bottom);
        @extend %py-3;
        @extend %px-4;
        @extend %w-100;
        &[aria-expanded="true"] {
            ~ .select-list {
                @extend %d-block;
            }
            .btn-text:after {
                transform: rotate(-180deg);
            }
        }
    }
    .btn-text {
        @extend %body-l;
        @extend %relative;
        @extend %neutral-5;
        @extend %flex-sb-c;
        &:after {
            @include icon(chevron-down, 16);
        }
    }
    .select-list {
        max-height: 14rem;
        transition: 300ms ease-in-out;
        right: 0;
        top: 4.8rem;
        @extend %zindex-dropdown-wrap;
        @extend %d-none;
        @extend %position-t-l;
        @extend %h-100;
        @extend %w-100;
        .select-item {
            transition: 300ms ease-in-out;
            height: 4.8rem;
            @include border(1, neutral-20, 10, bottom);
            @extend %p-4;
            @extend %text-m;
            @extend %bg-neutral-0;
            @extend %neutral-70;
            @extend %flex-fs-c;
            @extend %w-100;
            &:hover {
                @extend %bg-neutral-5;
            }
            &:focus {
                @extend %bg-neutral-10;
            }
        }
    }
}
// dropdown css ends
// Backdrop Modal CSS Start
.backdrop::before {
    content: "";
    background-color: hsl(var(--hsl-neutral-100) / .9);
    // pointer-events: none;
    @include position-combo("inset", null, null, fixed);
    @extend %zindex-backdrop-modal;
}
.backdrop {
    &.filter-backdrop::before {
        z-index: 7;
    }
}
.body-grey {
    @extend %bg-neutral-5;
    .footer-wrapper {
        &::before {
            @extend %bg-neutral-5;
        }
    }
}
.body-black {
    @extend %bg-neutral-100;
}
.body-white {
    @extend %bg-neutral-0;
    .footer-wrapper {
        &::before {
            @extend %bg-neutral-0;
        }
    }
}
.blue-gradient {
    .footer-wrapper {
        &::before {
            @include bgImg("cssimages/pattern/blue-gradient-bottom.png", null, 100% 100%);
        }
    }
}
.footer-grey {
    .footer-wrapper {
        @extend %mt-10;
        &:before {
            height: calc(100% + 10.4rem);
            top: -8rem;
            @extend %bg-neutral-5;
        }
    }
}
.content-app {
    height: max-content;
    border: .1rem solid var(--neutral-20);
    @extend %px-6;
    @extend %py-8;
    @extend %full-radius;
    @extend %bg-neutral-5;
    .app-action {
        @extend %flex;
        @extend %gap-4;
        @extend %mt-2;
    }
}
.grey-bg {
    @extend %bg-neutral-5;
}
.body-wrap {
    min-height: calc(var(--window-inner-height) - calc(var(--header-height) + var(--header-redirect-height)));
}
.waf-component.waf-loader {
    @extend %p-0;
    @extend %m-0;
}
.waf-loader,
.loader-wrapper {
    position: fixed;
    width: var(--window-inner-width);
    top: 0;
    left: 0;
    background-color: hsl(var(--hsl-neutral-100) / .5);
    @extend %h-100;
    @extend %zindex-loader;
    @extend %m-0;
    @extend %d-none;
    .loader {
        width: 15rem;
        height: 15rem;
        @extend %p-4;
        @extend %relative;
        @extend %circle-radius;
        @extend %flex-c-c;
        &:after,
        &::before {
            content: "";
            border: 0.3rem solid transparent;
            border-top-color: var(--primary-50);
            border-bottom-color: var(--secondary-50);
            transform: translate(-50%, -50%);
            @include position(50%, null, null, 50%);
            @extend %circle-radius;
        }
        &:after {
            width: calc(100% + 1rem);
            height: calc(100% + 1rem);
            -webkit-animation: loader 1s linear infinite;
            animation: loader 1s linear infinite;
        }
        &::before {
            width: calc(100% + 3rem);
            height: calc(100% + 3rem);
            -webkit-animation: loader-reverse 1s linear infinite;
            animation: loader-reverse 1s linear infinite;
        }
    }
}
// .wtc-races-page, .wtc-page{
//     .waf-loader{
//         top: calc(var(--header-redirect-height) + var(--header-height) + var(--secondary-header-height));
//     }
// }
// Backdrop Modal CSS End
// Common text styles Start
.bold {
    font-weight: 700;
}
.italic {
    font-style: italic;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
@media screen and (min-width: $md-min-width) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    @each $width in $flex-width {
        .flex#{$width} {
            width: calc($width * 1%);
            &-gap {
                width: calc((#{$width} - var(--space-1)) * 1%);
            }
        }
    }
}
.waf-livestrip {
    @extend %d-none;
}
:is(.livestrip) {
    .waf-livestrip {
        display: block;
    }
    .menu-wrap {
        top: var(--livestrip-height);
    }
}
.wtc-page,
.wtc-races-page,
.wtc-event-page {
    &.sticky {
        .secondary-nav {
            position: fixed;
            top: 0;
            left: 0;
            box-shadow: 0 0.4rem 2.5rem 0 hsla(var(--hsl-neutral-100)/0.2);
        }
        &.scroll-up {
            .secondary-nav {
                top: var(--header-height);
            }
            .menu-wrap {
                position: sticky;
            }
        }
    }
    &.scroll-up {
        .secondary-nav {
            top: calc(var(--showcase-height) - 5rem);
        }
        .menu-wrap {
            position: absolute;
            z-index: var(--z-header);
        }
        .site-header {
            @include bg(neutral-100);
        }
    }
}
.wtc-event-page {
    &.sticky {
        .menu-wrap {
            position: sticky;
            z-index: var(--z-header);
        }
        .site-header {
            @include bg(neutral-100);
        }
    }
}
.new-line {
    @extend %d-block;
}
.wtc-page {
    .wtc-header {
        @extend %d-block;
    }
    .waf-showcase {
        .preview-swiper {
            .article-thumbnail {
                height: 58rem;
            }
        }
    }
}
.wtc-races-page {
    .wtc-races-header {
        @extend %d-block;
    }
}
.wtc-event-page {
    .wtc-event-header {
        @extend %d-block;
        .secondary-nav {
            // top: calc(var(--showcase-height) - 5rem);
            top: 38rem;
        }
    }
}
.wtc-header,
.wtc-races-header,
.wtc-event-header {
    @extend %d-none;
}
.wtc-header {
    .redirect {
        .secondary-nav-anchor::after {
            aspect-ratio: 1/1;
            color: var(--neutral-100);
            transform: rotate(310deg);
        }
    }
}
.loader-active {
    .waf-loader {
        @include flex-config(flex, null, center, center);
    }
}
.inline-loader-active {
    .loader-wrapper {
        position: static;
        width: 100%;
        background-color: transparent;
        min-height: 50rem;
        @include flex-config(flex, null, center, center);
    }
}
.overlay-loader {
    @extend %relative;
    .loader-wrapper {
        background-color: var(--neutral-0);
        @extend %w-100;
        @extend %h-100;
        @extend %zindex-backdrop-modal;
        @include position-combo(center);
    }
}
.waf-listing {
    .head-wrap {
        @extend %flex-sb-c;
        @extend %mb-4;
    }
    .title {
        @extend %title-60;
        @extend %neutral-100;
    }
    .head-tab li a {
        @extend %btn-default;
        @extend %primary-40;
        @extend %text-mb;
        &:after {
            @include icon(arrow-right, 19);
            @extend %ml-2;
        }
    }
}
.waf-listing.waf-horizontal-list {
    @include article-listing(horizontal);
}
.waf-listing.waf-overlay-list {
    @include article-listing(overlay);
}
//photo-modal common
.player-photos-wrapper,
.article-swiper,
.waf-article-gallery {
    @extend %py-20;
    .head {
        @extend %mb-10;
    }
    .photos-swiper {
        @extend %px-5;
        .swiper-button {
            @extend %d-none;
        }
    }
    .article,
    .article-gallery {
        &-item {
            @extend %relative;
            &:not(:last-child) {
                @extend %mb-6;
            }
            &:hover {
                .btn-more {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 76.04%),
                        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
                    &::before {
                        @include flex-config(flex, row, center, center);
                    }
                }
            }
        }
        &-list {
            display: block;
        }
    }
    .img-box {
        aspect-ratio: 1/1;
        @extend %border-radius-s;
    }
    img {
        object-fit: cover;
    }
    .btn {
        &-more {
            @extend %border-radius-s;
            @extend %flex-c-c;
            @include position-combo(inset);
            &::before {
                display: none;
                width: 6.4rem;
                height: 6.4rem;
                background-color: hsl(var(--hsl-neutral-0)/0.2);
                color: var(--neutral-0);
                border-radius: 0.8rem;
                @include icon(magnifying-glass-plus, 26);
            }
        }
        &-text {
            @extend %font-0;
        }
    }
}
.player-photos-wrapper,
.article-gallery,
.waf-article-gallery {
    .modal-window {
        background: hsla(var(--hsl-neutral-100)/.7);
        @extend %d-none;
        &.active {
            @extend %d-block;
        }
        .modal-body {
            @extend %flex-column-c-n;
            @extend %h-100;
        }
        .article {
            &-list {
                @extend %p-0;
                @extend %flex;
            }
            &-thumbnail,
            &-wrap,
            &-list {
                @extend %h-100;
                @extend %w-100;
            }
        }
        .thumbnail-swiper {
            @extend %w-100;
            @extend %mt-4;
            .img-box {
                aspect-ratio: 1/1;
                height: unset;
            }
            .article {
                &-list {
                    @extend %mx-4;
                }
                &-item {
                    opacity: 0.5;
                    &.swiper-slide-thumb-active {
                        opacity: 1;
                    }
                }
            }
            .swiper-button {
                @extend %d-none;
            }
        }
        .preview-swiper {
            height: calc(100% - 17rem);
            @extend %mx-4;
            @extend %pb-16;
            @extend %relative;
        }
        .img-box {
            aspect-ratio: unset;
            @extend %h-100;
            @extend %bg-transparent;
            img {
                object-fit: contain;
                @extend %h-100;
            }
        }
        .btn-close {
            top: 2rem;
        }
        .swiper {
            &-button {
                border-color: var(--neutral-0);
                @include position(unset, 0, 0);
                @extend %flex;
                &-prev {
                    right: 6rem;
                    left: auto;
                }
                &::after {
                    color: var(--neutral-0);
                }
            }
            &-pagination {
                width: max-content;
                @include position(auto, 13rem, 0, auto);
                @extend %neutral-0;
            }
        }
        .meta-label{
            @extend %neutral-0;
            @include position(null, null, -6.4rem);
        }
    }
}
.waf-player,
.waf-detail {
    .article,
    .player {
        &-eventinfo-wrapper {
            .card {
                &-item {
                    grid-template-columns: 10rem calc(100% - 10rem);
                }
                &-title {
                    text-overflow: unset;
                    white-space: unset;
                }
            }
        }
    }
}
.ranking-page {
    background-attachment: fixed;
    @include bgImg("svg/vector-gradient.svg", top 6rem right 50%, 73.3rem 73.3rem);
}
@each $federation, $federation-img in $federation-bg {
    .#{$federation} {
        .waf-listing.search-only,
        .waf-documents {
            .waf-head {
                background: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}") bottom center/cover no-repeat;
                &::before {
                    content: unset;
                }
            }
        }
    }
}
.waf-no-data {
    .content-wrapper {
        min-height: 45rem;
        border-radius: 2.4rem;
        margin-block: var(--space-10);
        @include background(var(--neutral-0), 'cssimages/pattern/no-data-mobile.png', center / cover no-repeat);
        @extend %w-100;
        @extend %text-center;
        @extend %flex-column-c-c;
    }
    .title {
        @extend %font-24-pb;
        @extend %mb-0;
    }
    .description {
        @extend %font-16-pb;
        @extend %mt-2;
        @extend %mb-6;
    }
    .btn-site {
        border: 0.1rem solid var(--neutral-0);
        min-height: 4.8rem;
        width: max-content;
        @include tag-config-1(fill, text, neutral-5, primary-50);
        @extend %flex-c-c;
        @extend %px-4;
        @extend %rounded-radius;
        &:hover {
            background-color: var(--primary-70);
        }
    }
    .text {
        @extend %font-14-pb;
    }
}
//
@include mq(col-tablet) {
    .wtc-races-page,
    .wtc-page,
    .wtc-event-page {
        &.sticky {
            &.scroll-up {
                .secondary-nav {
                    top: var(--header-height);
                }
                .body-wrap {
                    padding-top: var(--secondary-header-height);
                }
            }
        }
        &.scroll-up {
            .menu-wrap {
                position: sticky;
            }
            .secondary-nav {
                top: calc(var(--showcase-height) + var(--space-10));
            }
        }
        .body-wrap {
            padding-top: calc(var(--header-height) + var(--header-redirect-height));
        }
        .site-header {
            @include bg(neutral-100);
        }
    }
    .wtc-event-page {
        &.scroll-up {
            .body-wrap {
                padding-top: var(--header-height);
            }
            .wtc-event-header {
                .secondary-nav {
                    top: calc(var(--showcase-height) + var(--space-2));
                }
            }
        }
    }
    .wtc-page {
        .body-wrap {
            padding-top: calc(var(--header-height) + var(--header-redirect-height) + var(--secondary-header-height));
        }
        &.scroll-up {
            .body-wrap {
                padding-top: calc(var(--secondary-header-height));
            }
            .secondary-nav {
                top: calc(var(--showcase-height) + var(--space-16));
            }
        }
        .waf-showcase {
            .preview-swiper {
                .article-thumbnail {
                    height: 60rem;
                }
            }
        }
    }
    //
    .player-photos-wrapper,
    .article-swiper,
    .waf-article-gallery {
        .head {
            margin-bottom: -8rem;
        }
        .article,
        .article-gallery {
            &-list {
                display: flex;
            }
            &-item {
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
        .photos-swiper {
            position: relative;
            padding-top: 13rem;
            padding-inline: 0;
            .swiper-button {
                display: flex;
            }
        }
        .swiper {
            &-button {
                border-color: var(--primary-50);
                @include position(3rem, 6rem, null, auto);
                &-next {
                    right: 0;
                }
                &::after {
                    color: var(--primary-50);
                    font-size: 2.4rem;
                }
            }
            &-pagination-fraction {
                right: 13.4rem;
                top: 5.5rem;
                width: max-content;
                bottom: unset;
                left: unset;
                font-size: 1.6rem;
            }
        }
    }
    //
    .player-photos-wrapper,
    .article-gallery,
    .waf-article-gallery {
        .modal-window {
            .thumbnail-swiper {
                margin-top: 4rem;
            }
            .preview-swiper {
                padding-bottom: var(--space-16);
                .swiper-button {
                    display: flex;
                }
            }
        }
    }
    .footer-grey {
        .footer-wrapper {
            margin-top: var(--space-24);
            &:before {
                height: calc(100% + 12rem);
                top: calc(-1 * var(--space-24));
            }
        }
    }
    .waf-player,
    .waf-detail {
        .article,
        .player {
            &-eventinfo-wrapper {
                .card {
                    &-title {
                        font-size: 2rem;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    .ranking-page {
        background-position: top 62rem right -6rem;
    }
    .waf-no-data {
        .content-wrapper {
            min-height: 32.2rem;
            margin-block: var(--space-16) var(--space-14);
            @include bgImg('cssimages/pattern/no-data-desktop.png', center, cover);
        }
        .title {
            font-size: 3.2rem;
        }
        .description {
            font-size: 2rem;
        }
    }
}
.item-type-photo {
    @extend %relative;
    .item-type-icon:before {
        @include icon(photo, 21);
    }
}
.item-type-video {
    @extend %relative;
    .item-type-icon:before {
        @include icon(play, 21);
    }
}
.item-type-photo,
.item-type-video {
    .item-type-icon {
        width: 4rem;
        height: 4rem;
        @include bg(neutral-0, 2);
        @include position(1.6rem, null, null, 1.6rem);
        @extend %border-radius-s;
        @extend %flex-c-c;
        @extend %neutral-0;
        @extend %zindex-overlay-icon;
    }
}
@include mq(col-lg) {
    .wtc-races-page,
    .wtc-page {
        &.sticky {
            &.scroll-up {
                .secondary-nav {
                    top: var(--header-height);
                    // top: calc(var(--header-height) + var(--header-redirect-height));
                }
            }
        }
        &.scroll-up {
            .secondary-nav {
                top: calc(var(--showcase-height) + var(--space-10));
            }
        }
    }
    .item-type-photo,
    .item-type-video {
        .item-type-icon {
            width: 6.4rem;
            height: 6.4rem;
        }
    }
    .wtc-header {
        .redirect {
            .secondary-nav-anchor::after {
                @include icon(arrow-right, 20);
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-listing {
        .title {
            font-size: 2.4rem;
        }
    }
}
@include mq(col-lg) {
    .content-app {
        max-width: 32rem;
    }
}
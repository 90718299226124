@use "../config/" as *;
// @ '../news-letter.scss';
.footer {
    &-wrapper {
        @extend %mx-4;
        @extend %mb-4;
        @extend %py-10;
        @extend %px-4;
        @extend %bg-neutral-0;
        @extend %relative;
        @extend %border-radius-l;
        &:before {
            content: '';
            width: calc(100% + var(--space-8));
            height: calc(100% + var(--space-4));
            inset-inline: var(--space-4-neg);
            z-index: -1;
            @extend %position-t-l;
            @extend %bg-neutral-100;
        }
        .waf-component {
            @extend %p-0;
            @extend %m-0;
        }
        .layout-wrapper {
            max-width: 100%;
        }
    }
    &-client-section {
        .client {
            &-section {
                @include border(1, neutral-20, 10, bottom, solid);
                @extend %pb-6;
                @extend %mb-6;
                &.competition-section {
                    @extend %d-none;
                    .client-logo {
                        width: auto;
                        max-width: 20rem;
                        aspect-ratio: 25/6;
                    }
                }
            }
            &-title {
                @extend %text-center;
                @extend %mb-4;
                @extend %body-m;
            }
            &-item {
                @extend %flex-c-c;
                @extend %flex-wrap;
            }
            &-logo {
                // width: 14.3rem;
                // aspect-ratio: 16/9;
                @extend %p-2;
                image {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
    &-top-section {
        @include border(1, neutral-20, 10, bottom, solid);
        @extend %pb-6;
        @extend %mb-6;
        .footer-section-wrapper {
            @include flex-config(flex, column, null, null);
            // @extend %gap-12;
        }
        .social {
            &-item {
                width: 2.4rem;
                height: 2.4rem;
                aspect-ratio: 1/1;
            }
            &-link-list {
                @extend %flex-c-c;
            }
            &-head {
                @extend %d-none;
            }
            &-list {
                max-width: 32rem;
                overflow: unset;
                padding-inline: 0;
                gap: 0;
                padding-top: var(--space-10);
                @extend %flex-sb-c;
                // @extend %gap-5;
                @extend %w-100;
                .social-text {
                    @extend %font-0;
                    @extend %w-100;
                    @extend %h-100;
                    @extend %flex-c-c;
                    &:before {
                        @extend %font-24;
                        @extend %neutral-70;
                        @extend %w-100;
                        @extend %h-100;
                        @extend %flex-c-c;
                    }
                    &.icon-twitter::before {
                        font-size: 1.8rem;
                    }
                }
            }
        }
        .social-menu-wrapper {
            border-top: none;
            .social-link-list {
                @extend %px-0;
                @extend %pb-0;
            }
        }
        .form-group {
            .btn-fill {
                cursor: pointer;
                &.btn-disabled {
                    cursor: not-allowed;
                    color: var(--neutral-40);
                    @extend %bg-informative-10;
                    &:hover {
                        @extend %bg-informative-10;
                    }
                }
            }
        }
        #errorMsgDiv {
            @extend %w-100;
        }
        .errorDiv {
            @extend %negative-90;
        }
        .successDiv {
            @extend %positive-90;
        }
    }
    &-middle-section {
        @include border(1, neutral-20, 10, bottom, solid);
        @extend %pb-6;
        @extend %mb-6;
    }
    &-bottom-section {
        .footer-section-wrapper {
            @extend %flex-sb-c;
            @extend %gap-10;
        }
        .copyright-text {
            @extend %neutral-70;
            @extend %text-right;
            @extend %body-m;
        }
    }
    &-logo img {
        width: 15rem;
        filter: invert(27%) sepia(71%) saturate(2390%) hue-rotate(218deg) brightness(87%) contrast(105%);
    }
    &-promo {
        @extend %flex;
        @extend %flex-wrap;
        @extend %gap-2;
        .nav-anchor {
            @extend %body-mb;
            @extend %neutral-100;
        }
    }
    &-promo-link {
        @extend %mt-6;
    }
    &-promo-item .nav-anchor {
        // not(:first-child, :last-child)
        @extend %relative;
        &:before {
            content: '';
            width: 0.3rem;
            height: 0.3rem;
            display: inline-block;
            vertical-align: middle;
            @extend %circle-radius;
            @extend %bg-neutral-40;
            @extend %mr-2;
        }
    }
    &-promo-item:nth-child(2n+1) .nav-anchor:before {
        @extend %d-none;
    }
    &-nav-link {
        &:first-child {
            @extend %d-none;
        }
        &.submenu {
            & > .nav-anchor {
                font-size: 1.6rem;
                font-weight: 700;
                transition: all 0.3s ease-in-out;
                @include border(1, neutral-20, 10, bottom);
                @extend %relative;
                @extend %w-100;
                &:after {
                    font-weight: 400;
                    transition: all 0.3s ease-in-out;
                    @include icon(chevron-down, 20);
                }
                &[aria-expanded="true"] {
                    border-color: var(--primary-50);
                }
                &[aria-expanded="true"]:after {
                    transform: rotate(180deg);
                }
                &[aria-expanded="true"] ~ .sub-ul {
                    transition: all 0.3s ease-in-out;
                    transform: scaleY(1);
                }
                &[aria-expanded="true"] ~ .sub-ul .nav-link {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
            .nav {
                &-link {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease-in-out;
                }
                &-anchor {
                    @extend %h-100;
                    @extend %py-4;
                    @extend %flex-sb-c;
                    @include border(1, neutral-20, 10, bottom);
                }
            }
            .sub-ul {
                position: static;
                max-height: unset;
                box-shadow: unset;
                min-width: unset;
                overflow-y: unset;
                padding: 0;
                @extend %d-block;
                @extend %mt-4;
            }
        }
    }
}
.wtc-page,
.wtc-races-page {
    .footer {
        &-client-section {
            @extend %d-block;
        }
    }
}
@include mq(col-sm) {
    .footer {
        &-top-section {
            .form {
                &-group {
                    gap: var(--space-4);
                    flex-wrap: wrap;
                    @include flex-config(flex, row, flex-start, center);
                    // .btn-fill {
                    //     width: 12rem;
                    // }
                }
                &-element {
                    flex-grow: 1;
                    margin-bottom: 0;
                    width: calc(100% - 14rem);
                    margin-right: var(--space-4);
                }
            }
        }
        &-middle-section {
            .footer-promo-item:nth-child(2n+1) .nav-anchor:before {
                display: inline-block;
            }
            .footer-promo-item:first-child .nav-anchor:before {
                display: none;
            }
        }
    }
}
@include mq(col-tablet) {
    .footer {
        &-nav-link.submenu {
            .nav-anchor {
                padding: var(--space-0) var(--space-0) var(--space-2) var(--space-0);
            }
            > .nav-anchor {
                padding-bottom: var(--space-4);
            }
        }
    }
}
@include mq(col-md) {
    .footer {
        &-top-section {
            .footer-section-wrapper {
                // @include flex-config(flex, row, space-between, center);
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: var(--space-4);
            }
            .social {
                &-link-list {
                    justify-content: flex-end;
                }
                &-list {
                    max-width: 28rem;
                    padding: 0;
                    gap: var(--space-6);
                }
            }
            .form-group {
                gap: unset;
                .btn-fill {
                    width: 12rem;
                    cursor: pointer;
                }
            }
            .social-menu-wrapper {
                margin-top: 1rem;
                .social-link-list {
                    height: auto;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .footer {
        &-wrapper {
            padding-inline: var(--space-10);
            max-width: calc(100% - 2.4rem);
        }
        &-menu-link {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: var(--space-5);
        }
        &-nav-link.submenu {
            .nav-anchor {
                border-bottom: none;
                &:after {
                    content: unset;
                }
            }
            .nav-link {
                height: auto;
                opacity: 1;
                visibility: visible;
            }
            .sub-ul {
                margin: var(--space-0);
                transform: unset;
            }
        }
        &-promo {
            gap: var(--space-4);
        }
        &-promo-item .nav-anchor:before {
            margin-right: var(--space-4);
        }
        &-client-section {
            .client {
                &-logo {
                    padding-inline: var(--space-4);
                }
                &-section {
                    &.competition-section {
                        .client-logo {
                            max-width: 33.5rem;
                        }
                    }
                }
            }
        }
    }
}
@include mq(col-xl) {
    .footer-wrapper {
        padding-inline: var(--space-14);
        margin-inline: var(--space-6);
        margin-bottom: var(--space-6);
        &:before {
            width: calc(100% + var(--space-12));
            height: calc(100% + var(--space-6));
            inset-inline: var(--space-6-neg);
        }
    }
    .footer-client-section .client-section,
    .footer-top-section,
    .footer-middle-section {
        padding-bottom: var(--space-14);
        margin-bottom: var(--space-14);
    }
    .footer-menu-link {
        gap: var(--space-6);
    }
}
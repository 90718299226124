// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation starts
// Showcase pagination animation
@keyframes carouselDotsAnimation {
  100% {
    width: 100%;
  }
}
@keyframes loader {
  0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }

  50% {
      -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
      transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
  }

  100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}
@keyframes loader-reverse {
  0% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }

  50% {
      -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
      transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
  }

  100% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
}
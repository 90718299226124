@use "variables" as *;
[class^="icon-"] {
	font-size: 0;
	font-style: normal;
	color: var(--white);
}
[class^="icon-"]::before {
	font-family: $font-icon;
	font-size: 1.8rem;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@use "./config" as *;
.modal {
	&-window {
		display: flex;
		border: none;
		width: var(--window-inner-width);
		height: var(--window-inner-height);
		z-index: var(--z-modal);
		@extend %bg-neutral-100;
		@include position(0, 0, 0, 0, fixed);
		.btn-close {
			@include position(var(--header-height),
				var(--space-4),
				null,
				null,
				fixed);
			&::before {
				display: none;
			}
			&::after {
				content: "\e820";
				font-family: $font-icon;
				@extend %font-18;
				@extend %neutral-0;
			}
		}
	}
	&-footer {
		align-self: flex-end;
		@extend %w-100;
		@include flex-config(flex, row-reverse, space-between, flex-end);
	}
}
@media screen and (min-width: $md-min-width) {
	.modal {
		&-body {
			max-width: 80rem;
			margin: 0 auto;
		}
		&-window {
			padding: var(--space-8);
			&::before {
				display: block;
			}
			&::after {
				left: -14rem;
				bottom: -10rem;
			}
			.btn-close {
				top: calc(var(--header-height) + var(--header-redirect-height));
				right: 4rem;
				&::after {
					font-size: 2rem;
				}
			}
		}
	}
}
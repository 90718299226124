@use '../config/' as *;


.waf-newsletter {
    @extend %p-0;
    @extend %m-0;
    .title {
        @extend %body-l;
        @extend %neutral-70;
        @extend %mb-8;
        &::first-line{
            @extend %mb-2;
            @extend %title-40;
            @extend %neutral-100;
        }
    }
    .btn-fill{
        font-size: 1.4rem;
        @extend %uppercase;
    }
    .form {
        &-group {
            @extend %flex-column;

            .btn-fill {
                @extend %w-100;
                @extend %btn-fill;
            }
        }

        &-element {
            @extend %mb-4;

            .control-label {
                @extend %d-none;
            }


        }

        &-control {
            height: 4rem;
            @include border(1, neutral-40);
            @extend %w-100;
            @extend %border-radius-xs;
            @extend %text-m;
            @extend %neutral-40;
            @extend %py-3;
            @extend %px-4;
            @extend %neutral-100;

            &::placeholder {
                @extend %neutral-40;
            }
        }

    }

}



@include mq(col-lg) {
    .waf-newsletter{
        .title{
            &::first-line{
                font-size: 3.2rem;
            }
        }
    }
}
@use "../config" as *;
// margin and padding 0 for all elements and border box
// 1. as best practices in * there should be our font so that it will apply on all elements as we already know that buttons dont inherit font family
// 2. making all margin 0 so that we will apply our styling according to need
// 3. making all padding 0 so that we will apply our styling according to need
* {
    font-family: $font-primary; // 1
    @extend %m-0; // 2
    @extend %p-0; // 3
}
*,
*::before,
*::after {
    box-sizing: border-box; // we dont have to calculate the widths and height if padding or border is added
}
// 1. base font size and scroll behaviour for smooth scroll animation
// 2. for smooth scrolling
html {
    font-size: var(--base-font-size); // 1
    scroll-behavior: smooth; // 2
}
// body
// 1. As a best practice, apply a default `background-color`.
// 2. As a best practice, apply a default `color` so that if text color changes just change body text color.
body {
    font-family: $font-primary, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji"; // with backup fonts
    font-weight: $body-font-weight; // normal font weight for all texts
    line-height: $default-line-height; // default line height for every text elements
    font-size: $body-font-size; // body font size
    @extend %neutral-40; // 1
    @extend %neutral-100; // 2
    @extend %x-hidden;
}
hr {
    border-top: 0.1rem solid;
    @extend %neutral-100;
}
small,
label {
    font-size: 1.2rem;
}
big {
    font-size: 1.6rem;
}
sub,
sup {
    font-size: 1rem;
}
h1 {
    // @extend %title-10;
    @extend %title-10;
}
.select-list {
    @extend %flex-column;
    @extend %gap-2;
    .list-item {
        // @extend %body-sb;
        @extend %text-s;
        @extend %pb-1;
    }
}
h2 {
    @extend %font-24-pb;
    @extend %mb-2;
}
h3 {
    @extend %font-20-pb;
    @extend %mb-2;
}
h4 {
    @extend %font-18-pb;
    @extend %mb-2;
}
h5 {
    @extend %font-16-pb;
    @extend %mb-2;
}
h6 {
    @extend %font-14-pb;
    @extend %mb-2;
}
a {
    text-decoration: none;
    // @extend %neutral-100;
    @extend %primary-50;
    &:hover {
        text-decoration: none;
        @extend %primary-55;
    }
}
ul,
ol {
    list-style: none;
    @extend %p-0;
    @extend %m-0;
}
a:not([href]):not([class]) {
    &,
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
blockquote {
    margin: 0 0 1rem;
}
button {
    border: none;
    @extend %neutral-100;
    @extend %bg-transparent;
    &:not(:disabled) {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
}
//  textarea will not extend as a width
textarea {
    resize: vertical; // 1
}
input {
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &:focus-within {
        outline: none;
    }
}
iframe {
    border: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--space-4);
}
[hidden] {
    display: none;
}
@media screen and (min-width: $md-min-width) {
    // h1 {
    //     font-size: 3.5rem;
    // }
    h2 {
        font-size: 2.6rem;
    }
    h3 {
        font-size: 2.4rem;
    }
    h4 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.8rem;
    }
    h6 {
        font-size: 1.6rem;
    }
}
@use "../config" as *;
:root {
  --search-input: 5.4rem;
  // --tab-list: 8rem;
  --tab-list: 0;
  --title: 4rem;
  --more-results: 4.7rem;
  --search-modal-height: calc(var(--window-inner-height) - var(--header-height) - var(--space-8));
  --search-modal-top: calc(var(--header-height) + var(--space-2));
  --search-list-height: calc(var(--search-modal-height) - var(--search-input) - var(--title) - var(--more-results) - var(--space-14));
}
.search-results-modal {
  width: calc(100% - var(--space-8));
  max-height: var(--search-modal-height);
  padding: var(--space-6) var(--space-4) var(--space-8);
  @include position-combo("x-center", var(--search-modal-top), null, fixed);
  @extend %full-radius;
  @extend %d-none;
  @extend %zindex-search-result-modal;
  @extend %bg-neutral-0;
}
.search-no-result {
  padding: 6% var(--space-4);
  overflow-y: auto;
  @extend %my-4;
  @extend %mt-8;
  @extend %full-radius;
  @extend %flex-column;
  @extend %bg-neutral-5;
  .icon {
    @extend %mb-10;
    @extend %d-block;
    &::after {
      line-height: 1;
      @include icon(folder-open, 80);
      @extend %d-block;
      @extend %text-center;
    }
  }
  .title {
    @extend %mb-4;
    @extend %text-center;
  }
  .desc {
    @extend %neutral-70;
    @extend %text-center;
  }
  .btn-site {
    width: max-content;
    @extend %mx-auto;
    @extend %mt-10;
    @extend %btn-fill;
    .text {
      @extend %d-block;
      &::first-letter {
        @extend %uppercase;
      }
    }
  }
}
.site-search,
.search-section {
  @extend %common-input-swiper-btn;
  .btn-search[aria-expanded="true"] {
    @extend %bg-neutral-0;
    @extend %circle-radius;
    .btn-text::before {
      @extend %primary-50;
    }
    ~ .search-results-modal {
      @extend %d-block;
    }
  }
  .search {
    &-results {
      &-wrapper {
        @extend %flex-column;
      }
      &-tabs {
        @extend %w-100;
      }
      &-content {
        flex-grow: 1;
        @extend %flex-column;
      }
      &-list {
        max-height: var(--search-list-height);
        flex-grow: 1;
        overflow-y: auto;
        @include custom-scroll();
      }
      &-item {
        padding: var(--space-4) var(--space-2) var(--space-4) var(--space-11);
        border-bottom: .1rem solid var(--neutral-20);
        gap: var(--space-1);
        @extend %flex-column;
        @extend %relative;
        &::before {
          line-height: 1;
          transform: translate(0, -50%);
          @include position(50%, null, null, var(--space-1));
        }
        &.recent::before {
          @include icon(clock, 24);
        }
        &.trending::before {
          @include icon(arrow-trending-up, 24);
        }
        &.photo::before {
          @include icon(photo, 24);
        }
        &.calendar::before {
          @include icon(calendar, 24);
        }
        &.events::before {
          @include icon(calendar, 24);
        }
        &.athletes::before {
          @include icon(face-smile, 24);
        }
        &.news::before {
          @include icon(newspaper, 24);
        }
        &.galleries::before {
          @include icon(photo, 24);
        }
        &.videos::before {
          @include icon(play, 24);
        }
        &.downloads::before {
          @include icon(arrow-down-tray, 24);
        }
        &.federations::before {
          @include icon(flag, 24);
        }
        &.pages::before {
          @include icon(globe, 24);
        }
        .text {
          line-height: 1.5;
          @include truncate-text(2);
        }
      }
    }
  }
  .item-link {
    gap: var(--space-4);
    line-height: 1;
    @extend %flex-n-c;
    @extend %neutral-100;
  }
  .tab {
    &-list {
      height: auto;
      @extend %py-6;
    }
    &-item {
      width: unset;
      height: auto;
    }
    &-link {
      border: .1rem solid var(--neutral-20);
      display: inline-block;
      line-height: 1;
      @extend %rounded-radius;
      @extend %p-2-3;
      .text {
        font-weight: 700;
        @extend %neutral-100;
      }
    }
  }
  .highlight {
    font-weight: 700;
  }
  .date {
    font: 1.2rem/1 $font-primary;
    @extend %d-block;
    @extend %neutral-70;
  }
  .more-results-link {
    margin-top: var(--space-6);
    @extend %d-block;
    @extend %w-100;
    .text {
      font-weight: 700;
      gap: var(--space-2);
      @extend %flex-c-c;
      @extend %primary-50;
      &::after {
        @include icon(arrow-right);
      }
    }
  }
}
.site-search {
  .btn-search[aria-expanded="true"] {
    .btn-text::before {
      content: map-get($map: $icons, $key: close);
    }
  }
  .search-results-content {
    @extend %mt-6;
  }
}
.site-search,
.search-section {
  .btn-close {
    @extend %d-none;
  }
}
.search-input-wrap {
  + .btn-close {
    @extend %d-none;
  }
}
.search-input {
  + .btn-close {
    @extend %d-none;
  }
}
.search-input-wrap:has(.search-text) {
  + .btn-close {
    right: 1.6rem;
    @extend %d-block;
    .search-input {
      padding-right: var(--space-15);
    }
    &:hover {
      .btn-text::before {
        @extend %primary-55;
      }
    }
    &:active {
      .btn-text::before {
        @extend %primary-85;
      }
    }
    .btn-text::after {
      content: "Clear";
      @extend %primary-50;
      @extend %body-mb;
    }
    .btn-text::before {
      content: unset;
    }
  }
  .search-input {
    @extend %pr-14;
    + .btn-close {
      right: 1.6rem;
      @extend %d-block;
      &:hover {
        .btn-text::before {
          @extend %primary-55;
        }
      }
      &:active {
        .btn-text::before {
          @extend %primary-85;
        }
      }
      .btn-text::before {
        content: "Clear";
        @extend %primary-50;
        @extend %body-mb;
      }
    }
  }
}
// Adjust max-height of 'Search Results List' when 'User Searches'
.user-results {
  .site-search-form {
    @extend %mb-6;
  }
  .search-results-list {
    max-height: calc(var(--search-list-height) + var(--title) + var(--tab-list) - var(--space-6));
  }
}
// When livestrip present adjust search popup position/style
.livestrip {
  .search-results-modal {
    max-height: calc(var(--search-modal-height) - var(--livestrip-height));
    top: calc(var(--search-modal-top) + var(--livestrip-height));
  }
  .search-results-list {
    max-height: calc(var(--search-list-height) - var(--livestrip-height));
  }
  .user-results .search-results-list {
    max-height: calc(var(--search-list-height) + var(--title) + var(--tab-list) - var(--livestrip-height) - var(--space-6));
  }
}
@media screen and (max-width: $sm-mobile-max-width) {
  .search-no-result {
    .icon {
      margin-bottom: var(--space-6);
      &::after {
        font-size: 6.4rem;
      }
    }
    .btn-site {
      margin-top: var(--space-6);
    }
  }
}
@include mq(col-tablet) {
  :root {
    // --tab-list: 9.6rem;
    --tab-list: 0;
    --title: 4.8rem;
    --more-results: 6.3rem;
    --search-modal-height: calc(var(--window-inner-height) - var(--header-height) - var(--header-redirect-height) - var(--space-8));
    --search-list-height: calc(var(--search-modal-height) - var(--search-input) - var(--title) - var(--more-results) - 8rem);
  }
  .search-no-result {
    padding-block: 2.5%;
    margin-top: var(--space-8);
    margin-right: 0;
    .icon {
      margin-bottom: var(--space-6);
    }
    .title {
      font-size: 3.2rem;
    }
    .desc {
      font-size: 1.6rem;
    }
    .btn-site {
      width: fit-content;
      margin: var(--space-6) auto 0;
    }
  }
  .btn-close .btn-text::before,
  .item-close .btn-text::before {
    font-size: 2rem;
  }
  .site-search,
  .search-section {
    .search-results {
      &-modal {
        padding: var(--space-6) 8rem var(--space-14);
      }
      &-list {
        padding-right: var(--space-1);
        // @include custom-scroll();
      }
      &-item .text {
        font-size: 1.6rem;
      }
    }
    .swiper-button {
      &-prev,
      &-next {
        display: none;
      }
    }
    .tab-list {
      padding-block: var(--space-8);
      gap: var(--space-2);
      overflow-x: auto;
    }
    .title {
      margin-block: var(--space-2);
      font-size: 2rem;
    }
    .more-results-link {
      margin-top: var(--space-10);
      .text {
        justify-content: flex-start;
      }
    }
  }
  .site-search {
    .search-results-content {
      margin-top: var(--space-8);
      .title {
        margin-top: 0;
      }
    }
  }
  .livestrip {
    .search-results-modal {
      max-height: calc(var(--search-modal-height) - var(--livestrip-height));
      top: var(--search-modal-top);
    }
    .search-results-list {
      max-height: calc(var(--search-list-height) - var(--livestrip-height));
    }
    .user-results .search-results-list {
      max-height: calc(var(--search-list-height) + var(--title) + var(--tab-list) - var(--livestrip-height));
    }
  }
}
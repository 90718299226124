@use '../config/' as *;

.accordion {
    &-header {
        @extend %w-100;
        @extend %relative;
        .btn-text {
            @extend %font-12-pr;
        }
        &::after {
            transition: rotate .5s ease-out;
            @include icon(chevron-down, 20);
        }
        &[aria-expanded="true"] {
            &::after {
                rotate: 180deg;
            }
            ~ .accordion-body {
                grid-template-rows: 1fr;
                @extend %pt-4;
            }
        }
    }
    &-body {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows .5s ease-out;
    }
    &-content {
        @extend %hidden;
    }
}
@use "map" as *;

:root {

    @each $color,
    $shades in $hex-shades {

        @each $shade,
        $hsl in $shades {
            $h: nth($hsl, 1);
            $s: nth($hsl, 2);
            $l: nth($hsl, 3);
            --#{$color}-#{$shade}: hsl(#{$h} #{$s} #{$l});
            --hsl-#{$color}-#{$shade}: #{$h} #{$s} #{$l};
        }
    }
}
@use "../config/" as *;
.wtc-races-page,
.wtc-event-page {
	.site-header {
		@extend %zindex-header;
	}
	.btn-hamburger {
		position: unset;
		.line {
			stroke: var(--neutral-0);
		}
	}
	&.sticky {
		.btn-hamburger {
			position: fixed;
			.line {
				stroke: var(--neutral-100);
			}
		}
		&.scroll-up {
			.btn-hamburger {
				position: unset;
				.line {
					stroke: var(--neutral-0);
				}
			}
		}
	}
	&.scroll-up {
		.menu-wrap {
			position: fixed;
		}
	}
}
.wtc-races-header,
.wtc-event-header {
	.lang, .redirect{
		.secondary-nav-anchor::after {
			aspect-ratio: 1/1;
			color: var(--neutral-0);
		}
	}
	.redirect{
		.secondary-nav-anchor::after{
			transform: rotate(310deg);
		}
	}
	.secondary {
		&-nav {
			@include position(calc(var(--showcase-height) - 4.5rem));
			@extend %bg-transparent;
			.logo {
				display: none;
			}
			.sub-ul {
				// max-height: 10rem;
				// min-width: 20rem;
				// box-shadow: 0 .4rem 2.5rem 0 hsla(var(--hsl-neutral-100)/0.2);
				// top: 4.8rem;
				top: calc(100% + 1.5rem);
				.nav-text {
					@extend %neutral-100;
				}
				.nav-link:hover {
					@extend %bg-transparent;
				}
			}
		}
		&-nav-anchor {
			&[aria-expanded] {
				&::after {
					@extend %neutral-0;
				}
			}
		}
		&-nav-wrapper {
			@extend %relative;
		}
		&-nav-list {
			align-items: flex-start;
			width: 100%;
			white-space: nowrap;
			@extend %relative;
		}
		&-nav-link {
			align-items: center;
			&.back-btn {
				top: calc(-1 * (var(--showcase-height) - (var(--header-height) + var(--header-redirect-height)) - 4rem));
				@extend %absolute;
				@extend %m-0;
				&::before {
					content: "";
					font-family: $font-icon;
					color: var(--neutral-0);
					@include icon(arrow-small-left, 20);
				}
				> .secondary-nav-anchor {
					@extend %pl-2;
				}
				&:hover {
					@extend %bg-transparent;
				}
			}
			.nav-text {
				font-weight: 700;
				@extend %neutral-0;
			}
		}
	}
}
.sticky {
	.wtc-races-header,
	.wtc-event-header {
		.secondary {
			&-nav {
				background-color: var(--neutral-0);
			}
			&-nav-anchor {
				&[aria-expanded] {
					&::after {
						@extend %neutral-100;
					}
				}
			}
		}
		.nav-text {
			color: var(--neutral-100);
			font: 700 1.4rem $font-primary;
		}
		.logo {
			display: flex;
		}
		.lang, .redirect{
			.secondary-nav-anchor::after {
				color: var(--neutral-100);
			}
		}
	}
	.wtc-races-header, .wtc-page {
		.secondary-nav {
			.sub-ul {
				top: calc(100% + 2rem);
			}
		}
	}
}
.wtc-races-header {
	.secondary-nav-link.back-btn {
		display: none;
	}
}
@include mq(col-tablet) {
	.wtc-races-header,
	.wtc-event-header {
		.secondary {
			&-nav {
				top: calc(var(--showcase-height) + var(--space-8));
				.sub-ul {
					// top: calc(var(--secondary-header-height) - var(--space-2));
					top: calc(100% + 1rem);
				}
			}
			// &-nav-list {
			// 	padding-top: 0;
			// }
			&-nav-link {
				&.active,
				&:hover {
					@include bg(neutral-0, 3);
				}
			}
		}
	}
	.wtc-races-header {
		.secondary-nav-link {
			&.back-btn {
				top: calc(-1 * (var(--showcase-height) - (var(--header-height))));
				left: 4rem;
				display: flex;
			}
		}
}
	.sticky {
		.wtc-races-header,
		.wtc-event-header {
			.secondary {
				&-nav-list {
					// padding-top: var(--space-4);
					width: auto;
				}
				&-nav-link {
					&.active,
					&:hover {
						@include bg(neutral-5, 10);
					}
				}
			}
		}
		.wtc-races-header, .wtc-page {
			.secondary-nav {
				.sub-ul {
					top: calc(100% + 3rem);
				}
			}
		}
	}
	.wtc-event-page {
		.wtc-event-header {
			.secondary-nav {
				top: calc(var(--showcase-height) + var(--space-6));
			}
		}
		.secondary-nav {
			.secondary-nav-list {
				padding-inline: var(--space-6);
			}
		}
	}
}
@include mq(col-lg) {
	.wtc-races-header,
	.wtc-event-header {
		.redirect{
			.secondary-nav-anchor::after{
				@include icon(arrow-right, 20);
			}
		}
		.lang {
			margin-left: auto;
			.secondary-nav-anchor::after {
				@include icon(globe, 20);
			}
		}
		.secondary {
			&-nav {
				top: calc(var(--showcase-height) + var(--space-14));
			}
			&-nav-wrapper {
				position: unset;
				height: 4.8rem;
				justify-content: flex-start;
			}
		}
	}
	.wtc-event-page {
		.wtc-event-header {
			.secondary-nav {
				top: calc(var(--showcase-height) + var(--space-14));
			}
		}
	}
	// .wtc-races-page {
	// 	.wtc-races-header {
	// 		.secondary-nav {
	// 			top: calc(var(--showcase-height) + var(--space-14));
	// 		}
	// 	}
	// }
	.sticky {
		.wtc-races-header,
		.wtc-event-header {
			.secondary {
				&-nav-wrapper {
					height: var(--secondary-header-height);
					justify-content: center;
				}
			}
		}
	}
}
@include mq(col-desktop) {
	.wtc-event-page {
		.secondary-nav {
			.secondary-nav-list {
				padding-inline: var(--container-white-space) var(--space-6);
			}
		}
	}
}
@media (min-width: $lg-min-width) and (max-height: 665px) {
	.wtc-races-header,
	.wtc-event-header {
		// .secondary-nav {
		// 	top: calc(var(--window-inner-height) - 9rem);
		// }
		.secondary-nav-link{
			&.back-btn {
				top: calc(-1 * (var(--showcase-height) - (var(--header-height))));
			}
		}
	}
}
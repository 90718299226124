@use "./config/" as *;
@forward "./main.scss";

.waf-certificate-detail{
    padding: 0 0 var(--space-20);
    margin-bottom: 0;
    @extend %common-input-swiper-btn;
    @extend %page-head-blue-bg;
    @extend %player-list;
    .waf-body {
        border-radius: unset;
        background-color: unset;
        margin-top: -10rem;
    }
    .head-wrap{
        margin-block: 16rem;
    }
    .form-wrapper, .search-body-wrapper{
        @extend %border-radius-m;
        @extend %bg-neutral-0;
    }
    .form-wrapper {  
      @extend %p-6-4;
      @extend %mb-8;
    }
    .form-title{
        @extend %mb-8;
    }
    .title{
        @extend %title-60;
        @extend %mb-0;
    }
    .search {
        &-body-wrapper {
          @extend %p-10-4;
        }
        &-list{
            @extend %mt-4;
        }
        &-item{
            @extend %gap-7;
            @extend %flex-sb-c;
            @extend %p-4-2;
            @include border(1, neutral-20, 10, bottom);
        }
        &-meta{
            @extend %gap-2;
            @extend %mt-4;
            @extend %d-none;
        }
        &-content{
            max-width: 82%;
        }
    }
    .meta{
        @extend %flex-n-c;
        &:not(:last-child)::after {
            content: "";
            width: .4rem;
            aspect-ratio: 1;
            @extend %circle-radius;
            @extend %ml-2;
            @extend %bg-neutral-20;
        }
        em{
            @extend %mr-1;
        }
    }
    .btn-link{
        max-width: 6.4rem;
        white-space: nowrap;
        @extend %primary-50;
        @extend %gap-2;
        &::after{
            @include icon(arrow-down-tray, 20);
        }
        .text{
            @extend %font-0;
            @extend %capitalize;
        }
    }
}

@include mq(col-tablet){
    .waf-certificate-detail{
        .head-wrap{
            margin-block: 21.8rem 16rem;
        }
        .form-wrapper{
            padding: var(--space-6);
        }
        .player{
            &-info{
                flex-direction: column;
                gap: 0;
                padding-inline: var(--space-4);
            }
        }
        .title{
            font-size: 1.6rem;
        }
        .search {
            &-body-wrapper {
                border-radius: 3.2rem;
            }
            &-list{
                margin-top: var(--space-8);
            }
            &-item{
                gap: var(--space-4);
                padding: var(--space-4);
            }
            &-meta{
                flex-wrap: wrap;
               @include flex-config(flex, row, null, center);
            }
        }
        .btn-link{
            max-width: unset;
            @include flex-config(flex, row, null, center);
            &:hover {
                cursor: pointer;
            }
            .text{
                font-size: 1.4rem;
                font-weight: 700;
            }
        }
    }
}

@include mq(col-lg){
    .waf-certificate-detail{
        .search-body-wrapper{
            padding: 8rem;
        }
    }
}
@use "variables" as *;
@use "map" as *;
@each $font-name,
$font-data in $font-detail {
    @each $font-alias,
    $font-details in $font-data {
        @each $font-family,
        $font-weight in $font-details {
            @font-face {
                font-family: #{$font-name};
                src: local("#{$font-family}"),
                url("/static-assets/build/fonts/#{$font-family}?v=#{$font-version}#iefix") format("embedded-opentype"),
                url("/static-assets/build/fonts/#{$font-family}.woff2?v=#{$font-version}") format("woff2"),
                url("/static-assets/build/fonts/#{$font-family}.woff?v=#{$font-version}") format("woff"),
                url("/static-assets/build/fonts/#{$font-family}.ttf?v=#{$font-version}") format("truetype"),
                url("/static-assets/build/fonts/#{$font-family}.svg#?v=#{$font-version}#{$font-name}") format("svg");
                font-weight: #{$font-weight};
                font-style: normal;
                font-display: swap;
            }
        }
    }
}
@font-face {
    font-family: 'waf-font-icon';
    src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}');
    src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}#iefix') format('embedded-opentype'),
    url('/static-assets/build/fonts/waf-font-icon.woff2?v=#{$font-version}') format('woff2'),
    url('/static-assets/build/fonts/waf-font-icon.woff?v=#{$font-version}') format('woff'),
    url('/static-assets/build/fonts/waf-font-icon.ttf?v=#{$font-version}') format('truetype'),
    url('/static-assets/build/fonts/waf-font-icon.svg?v=#{$font-version}#waf-font-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@use "./map" as *;
@use "./mixins" as *;
@use "./variables" as *;
%btn {
  @include btn-common();
}
%btn-default {
  @extend %btn;
  @include btn-config-1(default, text, primary, null);
}
%btn-default-dark {
  @extend %btn;
  @include btn-config-1(default-dark, text, neutral, null);
}
%btn-fill {
  @extend %btn;
  @include btn-config-1(fill, text, neutral, primary);
}
%btn-fill-icon {
  @extend %btn;
  @include btn-config-1(fill, icon, neutral, primary);
}
%btn-fill-text-icon {
  @extend %btn;
  @include btn-config-1(fill, text-icon, neutral, primary);
}
%btn-fill-drak {
  @extend %btn;
  @include btn-config-1(fill-dark, text, neutral, primary);
}
%btn-fill-dark-icon {
  @extend %btn;
  @include btn-config-1(fill-dark, icon, primary, neutral);
}
%btn-fill-dark-text-icon {
  @extend %btn;
  @include btn-config-1(fill-dark, text-icon, neutral, primary);
}
%btn-outline {
  @extend %btn;
  @include btn-config-1(outline, text, primary, neutral);
}
%btn-outline-icon {
  @extend %btn;
  @include btn-config-1(outline, icon, primary, neutral);
}
%btn-outline-text-icon {
  @extend %btn;
  @include btn-config-1(outline, text-icon, primary, neutral);
}
%btn-outline-dark {
  @extend %btn;
  @include btn-config-1(outline-dark, text, primary, neutral);
}
%btn-outline-dark-icon {
  @extend %btn;
  @include btn-config-1(outline-dark, icon, primary, neutral);
}
%btn-outline-dark-text-icon {
  @extend %btn;
  @include btn-config-1(outline-dark, text-icon, primary, neutral);
}
%tag-outline {
  @extend %btn;
  min-height: 3.2rem; //overriding extend value
  @include tag-config-1(outline, text, neutral-100, neutral-20);
}
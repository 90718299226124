@use '../config/' as *;
.pagination {
    @extend %flex-c-c;
    @extend %gap-2;
    &-wrap {
        @extend %mt-10;
    }
    // &-dots {
    //     @extend %d-none;
    // }
    &-number {
        @extend %flex-n-c;
        @extend %gap-2;
    }
    .nav {
        &-first,
        &-last {
            @extend %d-none;
        }
        &-arrow {
            width: auto;
            @extend %gap-2;
            @extend %px-2;
            &::before,
            &::after {
                @extend %neutral-70;
            }
            &:hover {
                &::before,
                &::after {
                    @extend %neutral-0;
                }
            }
        }
        &-left {
            &::before {
                @include icon(chevron-left, 11);
            }
        }
        &-right {
            &::after {
                @include icon(chevron-right, 11);
            }
        }
    }
    a {
        width: 3.2rem;
        height: 3.2rem;
        cursor: pointer;
        @include flex-config(inline-flex, null, center, center);
        @include border(1, neutral-40, 2);
        @extend %relative;
        @extend %border-radius-xs;
        &.active,
        &:hover {
            @extend %bg-primary-50;
            @extend %neutral-0;
        }
        &.disabled {
            pointer-events: none;
            span {
                color: var(--neutral-40);
            }
        }
    }
}
@use "../config/" as *;
.waf-system-page{
    padding-top: calc(var(--header-height) + 13.7rem);
    isolation: isolate;
    @extend %mx-2-neg;
    @extend %bg-neutral-100;
    &::before{
        content: '';
        pointer-events: none;
        z-index: var(--z-pattern);
        @include background(null, "banner/system-page-bg.png", 75% -4rem / cover no-repeat);
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
    }
    &.waf-component{
        @extend %pb-0;
        @extend %my-0;
    }
    &.waf-service::before {
        @include background(null, "banner/system-service-bg.png", 75% -10rem / 200% no-repeat);
    }
    .waf-head{
        &::before {
            @extend %d-none;
        }
    }
    .waf-body{
        margin-top: 0rem;
        @extend %p-6;
        @extend %mb-14;
        @extend %border-radius-m;
        @extend %bg-neutral-0;
    }
    .text{
        &-head{
            line-height: 1.1;
            @extend %title-40;
            @extend %mb-4;
            @extend %flex-column-c-fs;
            &::before{
                font-weight: 400;
                @extend %mb-8;
                @include icon(info, 45.5);
            }
            &.large-text {
                font-size: 5.6rem;
            }
        }
        &-description{
            @extend %mb-6;
            @extend %neutral-70;
        }
    }
    .btn{
        &-fill{
            width: max-content;
            @extend %btn-fill;
            &:hover{
                border-color: var(--neutral-0);
                @extend %bg-neutral-70;
                @extend %neutral-0;
            }
            &:focus{
              border-color: var(--primary-85);
              @extend %neutral-0;
              @extend %bg-neutral-70;
            }
            &:disabled{
              border-color: var(--neutral-40);
              @extend %neutral-40;
              @extend %bg-neutral-70;
            }
        }
        &-link{
            font-weight: 700;
            @extend %primary-50;
        }
    }
    .useful-links-section{
        @extend %mt-8;
    }
    .link{
        &-head{
            @extend %mb-6;
            @extend %body-mb;
        }
        &-list {
            @extend %gap-6;
            @extend %flex;
            @extend %flex-wrap;
        }
    }
}

@include mq(col-tablet){
    .waf-system-page{
        &::after {
            background-position: 75% 0rem;
        }
        &.waf-component{
            padding-top: calc(var(--header-height) + var(--header-redirect-height) + 16.8rem);
        }
        .waf-body{
            margin-bottom: 14.4rem;
            padding: var(--space-14) var(--space-12);
        }
        .text{
            &-head{
                font-size: 5.6rem;
                &::before{
                    margin-bottom: var(--space-12);
                    font-size: 6.5rem;
                }
                &.large-text {
                    font-size: 10.4rem;
                }
            }
            &-description{
                font-size: 1.6rem;
                margin-bottom: var(--space-8);
            }
        }
        .useful-links-section{
            margin-top: var(--space-12);
        }
        .link{
            &:not(:last-of-type){
                margin-bottom: 0;
            }
            &-head{
                font-size: 2rem;
                margin-bottom: var(--space-10);
            }
            &-list{
                gap: var(--space-12);
                flex-wrap: wrap;
                @include flex-config(flex, row, flex-start, center);
            }
        }
    }
}

@include mq(col-lg){
    .waf-system-page {
        &::before {
            min-height: calc(var(--window-inner-height) - (var(--header-height) + var(--header-redirect-height)));
            background-position: 75% 0rem;
            background-size: 100%;
        }
        &.waf-service::before {
            background-size: 100%;
            background-position: 75% 0rem;
        }
        .layout-wrapper {
            @include large-wrapper();
        }
        .waf-body {
            max-width: 74rem;
            margin-left: var(--space-4);
        }
        .text-description {
            margin-bottom: var(--space-12);
        }
    }
}
@use "./config/" as *;
th,
td {
    border: 0.1rem solid hsl(var(--hsl-neutral-100) / .3);
    @extend %p-2-1;
}
table {
    min-width: 100%;
    border-collapse: collapse;
    border: 0.1rem solid hsl(var(--hsl-neutral-100) / .3);
}
.table {
    // border: 0.1rem solid hsl(var(--hsl-neutral-100) / .3);
    border-collapse: collapse;
    @extend %w-100;
    thead {
        @extend %neutral-100;
        // @extend %neutral-0;
    }
    th,
    td {
        @extend %capitalize;
        @extend %text-left;
        @extend %p-2;
    }
    tr {
        @extend %relative;
        &:not(:last-child) {
            border-bottom: 0.1rem solid hsl(var(--hsl-neutral-100) / .3);
        }
        &:nth-child(even) {
            @extend %neutral-100;
        }
    }
    &-row {
        @extend %w-100;
        @extend %flex-c-c;
        @extend %bg-neutral-0;
        @extend %neutral-100;
        &:not(:last-child) {
            @include border(1, neutral-20, 10, bottom);
        }
    }
    &-head {
        @extend %bg-neutral-100;
    }
    &-left {
        @extend %bg-neutral-90;
        @extend %w-40;
    }
    &-right {
        @extend %bg-neutral-100;
        @extend %x-auto;
        @extend %w-60;
    }
    &-data {
        flex-shrink: 0;
        height: 5rem;
        @extend %w-100;
        @extend %text-center;
        @extend %flex-c-c;
    }
    .text {
        @extend %neutral-100;
        // @extend %font-14-pb;
        @extend %text-m;
        // @extend %uppercase;
    }
}
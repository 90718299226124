@use "variables" as *;
@use "map" as *;
@mixin placeholder($color) {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
        color: $color;
    }
}
// shimmer
@mixin shimmer($width: null, $height: null, $radius: 50vmax) {
    width: $width;
    height: $height;
    display: block;
    font-size: 0;
    background: var(--shimmer-light);
    border-radius: $radius;
    overflow: hidden;
    position: relative;
    &::after {
        content: "";
        background-image: linear-gradient(-45deg,
                var(--shimmer-light) 0%,
                var(--shimmer-light) 40%,
                var(--shimmer-dark) 50%,
                var(--shimmer-light) 60%,
                var(--shimmer-light) 100%);
        position: absolute;
        inset: -150%;
        transform: translateX(-100%);
        animation: shimmerAnimation 2s linear 0s infinite normal forwards;
    }
}
// how to use
// @include shimmer()
// @include shimmer(100,20)
// @include shimmer(10,30,var(--space-2))
@mixin flex-config($display,
    $flex-direction: null,
    $justify-content: null,
    $align-items: null) {
    display: $display;
    flex-direction: $flex-direction;
    justify-content: $justify-content;
    align-items: $align-items;
}
// how to use
// @include flex-config(); // just flex
// @include flex-config(flex,column);
// @include flex-config(flex,null,center,center);
@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
    position: $position;
    @if $combo=="tl" {
        top: 0;
        left: 0;
    }
    @else if $combo=="tr" {
        top: 0;
        right: 0;
    }
    @else if $combo=="bl" {
        bottom: 0;
        left: 0;
    }
    @else if $combo=="br" {
        bottom: 0;
        right: 0;
    }
    @else if $combo=="inset" {
        inset: 0;
    }
    @else if $combo=="center" {
        inset: 0;
        margin: auto;
    }
    @else if $combo=="y-center" {
        inset-block: 0;
        margin-block: auto;
        right: $x;
        left: $y;
    }
    @else if $combo=="x-center" {
        inset-inline: 0;
        margin-inline: auto;
        top: $x;
        bottom: $y;
    }
}
// @include position-combo(tl); // top == left == 0
// @include position-combo(center);
@mixin position($top: null,
    $right: null,
    $bottom: null,
    $left: null,
    $position: absolute) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: $position;
}
// how to use
// @include position(1rem,2rem,3rem,4rem); // top right bottom left
// @include position(1rem,2rem,3rem,4rem,fixed); // top right bottom left fixed
@mixin card-count($count, $gap: null, $wrap: nowrap) {
    @include flex-config(flex, row);
    @if $gap !=null {
        gap: $gap;
    }
    @if $count % 1==0 {
        @if $wrap==wrap {
            flex-wrap: wrap;
            overflow-x: hidden;
        }
        @else if $wrap==nowrap {
            overflow-x: auto;
            overflow-y: hidden;
        }
        & > * {
            width: calc(100% / $count - (($count - 1) * $gap / $count));
            flex-shrink: 0;
        }
    }
    @else {
        overflow-x: auto;
        overflow-y: hidden;
        & > * {
            width: calc(100% / $count - ($count / ceil($count) * $gap));
            flex-shrink: 0;
        }
    }
}
// how to use
// @include card-count(2, var(--space-4));
// @include card-count(2.5, var(--space-4));
// @include card-count(3, var(--space-4), wrap); For wrapping
@mixin bg($color, $opacity: 10) {
    $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
    background-color: $color-hsla;
}
@mixin color($color, $opacity: 10) {
    $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
    color: $color-hsla;
}
// how to use
// @include bg(white,7) // color with opacity
// @include bg(black) // solid color
@mixin dropdown($status, $animation-delay: 300ms) {
    @if $status==close {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        pointer-events: none;
        transition: (clip-path $animation-delay linear);
    }
    @else if $status==open {
        pointer-events: all;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}
@mixin dropup($status, $animation-delay: 300ms) {
    @if $status==close {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
        transition: (clip-path $animation-delay linear);
    }
    @else if $status==open {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}
@mixin slide-left($status, $animation-delay: 300ms) {
    @if $status==close {
        clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
        transition: (clip-path $animation-delay linear);
    }
    @else if $status==open {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}
@mixin fade-in($status, $animation-delay: 300ms) {
    @if $status==close {
        opacity: 0;
        pointer-events: none;
        @include transition(opacity $animation-delay linear);
    }
    @else if $status==open {
        pointer-events: inherit;
        opacity: 1;
    }
}
@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: 0.8rem;
        height: 0.4rem;
    }
    &::-webkit-scrollbar-track {
        background: var(--neutral-10);
        border-radius: 0.8rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.8rem;
        background: var(--neutral-20);
    }
    &::-webkit-scrollbar-thumb:hover {
        border-radius: 0.8rem;
        background: var(--neutral-20);
    }
}
@mixin truncate-text($line-number, $height: auto) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: $line-number;
    @if $height !=auto {
        height: $height * 0.1rem;
    }
}
// how to use
// @include truncate-text(line-number, height);
// @include truncate-text(3, 40);
// @include truncate-text(4, 20);
// @include truncate-text(2);
@mixin truncate($line-number,
    $font-size,
    $line-height: null,
    $font-family: null) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: $line-number;
    font-family: $font-family;
    font-size: $font-size * 0.1rem;
    @if $line-height !=null {
        line-height: $line-height * 0.1rem;
    }
    @else if $line-height==null {
        $line-height: $font-size * 1.6;
    }
    height: calc($line-number * $line-height * 0.1rem);
}
// how to use
// @include truncate(line number, font-size,line-height, font-family)
// @include truncate(3, 20)
// @include truncate(3, 20,34,$font-pr)
@mixin grid($count, $gap: var(--space-2)) {
    display: grid;
    grid-template-columns: repeat($count, 1fr);
    gap: $gap;
}
// how to use
// @include grid(count of columns , space);
// @include grid(3,var(--space-2);
// @include grid(3,3rem);
// map
// change map as per project
@mixin icon($name, $size: 14, $line-height: null) {
    $icon: map-get($icons, $name);
    font-family: $font-icon;
    @if $icon !=null {
        content: $icon;
        font-size: $size * 0.1rem;
        @if $line-height !=null {
            line-height: $line-height * 0.1rem;
        }
    }
    @else {
        @error "Icon '#{$name}' not found in $icons map.";
    }
}
// how to use
// ////@include iconarrow-down)
// ////@include iconarrow-down,14,null)
@mixin border($width: 1,
    $color: null,
    $opacity: 10,
    $direction: all,
    $style: solid) {
    $opacity-decimal: calc($opacity / 10);
    $color-hsla: hsl(var(--hsl-#{$color}) / $opacity-decimal);
    @if $direction==all {
        border: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==left {
        border-left: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==right {
        border-right: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==top {
        border-top: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==bottom {
        border-bottom: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==y {
        border-block: calc($width * 0.1rem) $style $color-hsla;
    }
    @else if $direction==x {
        border-inline: calc($width * 0.1rem) $style $color-hsla;
    }
    @else {
        @error "Invalid direction #{$direction}, must be one of: all, left, right, top, bottom.";
    }
}
// how to use
// @include border(1,c-purple);
// @include border(1,c-purple,3,left,dotted);
// @include border(border-width,border-color,border-color-opacity,border-position,border-style);
// ***** color name is got through map name so use same name as used in map
@mixin overlay {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, var(--black), transparent);
        pointer-events: none;
        z-index: var(--z-overlay);
    }
}
// how to use
// use this inside that element where we need to add overlay
// @include overlay()
@mixin article-listing($type: card, $width: 11.5rem) {
    .article {
        &-list {
            @include grid(1, var(--space-4));
        }
        &-wrap {
            @include flex-config(flex, column, null, flex-start);
        }
        &-item {
            position: relative;
            border-radius: var(--border-radius-m);
            overflow: hidden;
            @include border(1, neutral-20);
            &:hover {
                .article-thumbnail img {
                    transform: scale(1.05);
                }
            }
        }
        &-thumbnail {
            width: 100%;
            img {
                transition: 500ms ease-in-out;
                object-fit: cover;
                object-position: center;
            }
            .img-box {
                border-radius: 0;
            }
        }
        &-content {
            width: 100%;
            padding: var(--space-6);
        }
        &-title {
            font-size: 1.4rem;
            margin-bottom: var(--space-4);
            @include truncate-text(3, 100);
            @include color(neutral-100);
        }
        &-meta {
            gap: var(--space-2);
            @include flex-config(flex, null, null, center);
            @include color(neutral-70);
            & > *:not(:first-child) {
                gap: var(--space-2);
                position: relative;
                @include flex-config(flex, null, null, center);
                &:before {
                    content: '';
                    width: 0.3rem;
                    height: 0.3rem;
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 50%;
                    @include bg(neutral-70);
                }
            }
        }
    }
    @if $type==overlay {
        .article {
            &-item {
                width: 100%;
                flex-shrink: 0;
                @extend %relative;
            }
            &-thumbnail {
                & > a {
                    @extend %relative;
                    &:after {
                        content: '';
                        background: linear-gradient(0deg, hsl(var(--hsl-pure-black-0)/0.1), hsl(var(--hsl-pure-black-0)/0.1)),
                            linear-gradient(180deg, hsl(var(--hsl-pure-black-0)/0) 0%, hsl(var(--hsl-pure-black-0)/0.8) 76.04%);
                        @extend %w-100;
                        @extend %h-100;
                        @extend %position-center;
                    }
                }
            }
            &-content {
                @include position(null, null, 0, null);
                @extend %pb-4;
                @extend %neutral-0;
                @extend %flex-column-fe-n;
            }
            &-description {
                @extend %d-none;
            }
            &-meta {
                color: var(--neutral-0);
                order: -1;
                @extend %text-s;
                @extend %mb-4;
            }
            &-readmore {
                @extend %d-none;
            }
            &-title {
                font-size: 2rem;
                margin-bottom: var(--space-0);
                height: auto;
                @include color(neutral-0);
            }
        }
        .meta {
            @extend %neutral-0;
        }
        .reaction-section {
            @extend %d-none;
        }
        @supports (-webkit-touch-callout: none) {
            .article {
                &-thumbnail {
                    & > a {
                        position: initial;
                    }
                }
            }
        }
        @media (min-width:$lg-min-width) {
            .article {
                &-item {
                    &.item-type-photo,
                    &.item-type-video {
                        .item-type-icon:before {
                            font-size: 2.8rem;
                        }
                    }
                }
                &-content {
                    padding-bottom: var(--space-8);
                }
            }
        }
    }
    @if $type==horizontal {
        .article {
            &-item {
                border: none;
                border-radius: 0;
            }
            &-wrap {
                flex-direction: row;
                align-items: flex-start;
            }
            &-thumbnail {
                width: $width;
                &::before {
                    content: unset;
                }
                .img-box {
                    border-radius: var(--border-radius-s);
                }
            }
            &-title {
                height: auto;
                margin-bottom: 0;
                // -webkit-line-clamp: 4;
                @extend %title-60;
                @extend %mt-4;
            }
            &-content {
                width: calc(100% - $width);
                position: unset;
                padding: 0 0 0 var(--space-2);
                @include flex-config(flex, column, null, null);
                & > a {
                    order: 1;
                }
            }
        }
        @media (min-width:$md-min-width) {
            .article {
                &-thumbnail {
                    width: 50%;
                }
                &-content {
                    padding-inline: var(--space-6);
                    width: 50%;
                    padding-right: 0;
                }
                &-title {
                    font-size: 2.4rem;
                    -webkit-line-clamp: 4;
                }
            }
        }
    }
    @media (min-width:$lg-min-width) {
        .article {
            &-title {
                font-size: 2.4rem;
                line-height: 3.36rem;
            }
        }
    }
}
// how to use
// @include listing-card(rhs);
// @include listing-card(card);
// @include listing-card(overlay);
@mixin bgImg($image-location, $position: null, $size: null, $no-repeat: no-repeat) {
    background-image: url("/static-assets/images/#{$image-location}?v=#{$image-version}");
    background-repeat: $no-repeat;
    background-position: $position;
    background-size: $size;
}
@mixin background($bg-color: null, $bg-Img: null, $bg-Props...) {
    @if $bg-Img ==null {
        background: $bg-Props;
    }
    @else if $bg-color ==null {
        background: url('/static-assets/images/#{$bg-Img}?v=#{$image-version}') $bg-Props;
    }
    @else {
        background: $bg-color url('/static-assets/images/#{$bg-Img}?v=#{$image-version}') $bg-Props;
    }
}
// how to use
// background("cssimages/svg/vector.svg", center / contain no-repeat);
// how to use
// @include bgImg("svg/logo.svg") // make sure to add double quotes
@mixin hamburger() {
    .btn-hamburger {
        width: 4rem;
        height: 4rem;
        aspect-ratio: 1/1;
        order: -1;
        @extend %flex-c-c;
        &[aria-expanded="true"] {
            @extend %bg-neutral-0;
            @extend %circle-radius;
            ~ .header-nav {
                right: var(--space-2-neg);
                left: var(--space-2-neg);
            }
            &::after {
                @include icon(close, 20);
                @extend %primary-50;
            }
        }
        &:after {
            content: '\e821';
            font: 1.6rem $font-icon;
            @extend %neutral-0;
        }
        svg {
            @extend %d-none;
        }
    }
}
// button mixin starts
@mixin btn-common {
    min-height: 4.8rem;
    border-radius: 50vmax;
    padding: 0 var(--space-3);
    border: 1px solid transparent;
    background-color: transparent;
    font-weight: 700;
    @include flex-config(flex, null, center, center);
    & > * {
        font-weight: 700;
        font-size: 1.4rem;
        color: inherit;
        gap: var(--space-2);
        @include flex-config(flex, null, center, center);
        &:after {
            line-height: 1;
            color: inherit;
        }
    }
}
@mixin btn-config-1($type: null, $format: null, $color: null, $bg: null) {
    // text-color --> common style
    // color: var(--#{$color});
    //primary btn style starts
    @if $type==fill {
        background-color: var(--#{$bg}-50);
        color: var(--#{$color}-#{5});
        &:hover {
            background-color: var(--#{$bg}-70);
        }
        &:focus {
            background-color: var(--#{$bg}-80);
        }
    }
    @else if $type==outline {
        color: var(--#{$color}-50);
        border-color: var(--#{$color}-50);
        &:hover {
            color: var(--#{$color}-70);
            border-color: var(--#{$color}-70);
        }
        &:focus {
            color: var(--#{$color}-80);
            border-color: var(--#{$color}-80);
        }
    }
    @else if $type==default {
        color: var(--#{$color}-50);
        &:hover {
            color: var(--#{$color}-70);
        }
        &:focus {
            color: var(--#{$color}-80);
        }
    }
    @else if $type==fill-dark {
        background-color: var(--#{$bg}-0);
        color: var(--#{$color}-50);
        &:hover {
            background-color: var(--#{$bg}-5);
        }
        &:focus {
            background-color: var(--#{$bg}-0);
        }
    }
    @else if $type==outline-dark {
        color: var(--#{$color}-5);
        border-color: var(--#{$color}-5);
        &:hover {
            border-color: var(--#{$color}-0);
            @include bg(neutral-0, 3);
        }
        &:focus {
            color: var(--#{$color}-0);
            border-color: var(--primary-80);
            @include bg(neutral-0, 3);
        }
    }
    @else if $type==default-dark {
        color: var(--#{$color}-5);
        &:hover {
            color: var(--#{$color}-20);
        }
        &:focus {
            color: var(--#{$color}-0);
        }
    }
    @if $format==icon {
        & > * {
            font-size: 0;
            text-align: center;
            gap: 0;
        }
        & > *:after {
            content: '';
            color: inherit;
            font-size: inherit;
            line-height: 1;
        }
    }
    @else if $format==text {
        padding: 0 var(--space-5);
    }
    @else if $format==text-icon {
        & > *:after {
            color: inherit;
            line-height: 1;
        }
    }
    //tertiary btn style ends
}
//
@mixin mq($breakpoint) {
    $size: map-get($map: $media-query,
            $key: $breakpoint,
        );
    @media (min-width: $size) {
        @content;
    }
}
// how to use
// @include mq(col-md) // for media-query tablet
// @include mq(col-lg) // for media-query small screen laptop
// @include mq(col-xl) // for media-query medium screen laptop
// @include mq(col-xl) // for media-query large screen laptop
// @include btn-config-1(fill, text-icon, neutral-0, primary-90, user);
// @include btn-config-1(fill, text, primary, primary-90);
// @include btn-config-1(fill, icon, primary, primary-90, user);
// @include btn-config-1(outline, text-icon, primary);
// @include btn-config-1(outline, text, primary);
// @include btn-config-1(outline, icon, primary);
// @include btn-config-1(default, text, neutral-0, primary-90, user);
// button mixin ends
// @include backdropBlur(1rem)
@mixin mq($breakpoint) {
    $size: map-get($map: $media-query,
            $key: $breakpoint,
        );
    @media (min-width: $size) {
        @content;
    }
}
// how to use
// @include mq(col-md) // for media-query tablet
// @include mq(col-lg) // for media-query small screen laptop
// @include mq(col-xl) // for media-query medium screen laptop
// @include mq(col-xl) // for media-query large screen laptop
// SCSS Mixin to create separators (horizontal or vertical) with optional override.
// Parameters:
//   $space: Space value for the separator. Default is 100%.
//   $type: Type of separator ('hori' or 'vert'). Default is 'hori'.
//   $thickness: Thickness of the separator. Default is .1rem.
//   $color: Color of the separator. Default is hsl(0, 0%, 100%, 0.2).
@mixin separator($space: 100%, $type: x, $thickness: 0.1rem, $color: var(--neutral-0)) {
    // Set common styles for the separator
    // Horizontal separator
    @if $type==y {
        // Default styles
        position: relative;
        &::after {
            content: "";
            width: $thickness;
            height: $space;
            background: $color;
            @include position-combo(y-center, 0);
        }
    }
    // Vertical separator
    @else if $type==x {
        position: relative;
        &::after {
            content: "";
            width: $space;
            height: $thickness;
            background: $color;
            @include position-combo(x-center, null, 0);
        }
    }
}
// how to use
// @include separator(80%);
// @include separator(80%.x,.2rem,black,2);
// @include separator(length, direction, thickness , color, opacity);
// title with icon starts
@mixin title-with-icon($type: null, $icon, $size, $bgcolor: null) {
    @extend %relative;
    &:before {
        font-weight: 400;
        line-height: normal;
        @include icon($icon, $size);
        @extend %flex-fs-c;
        @extend %mb-2;
        @extend %border-radius-s;
        @if $type==shape {
            width: 4rem;
            height: 4rem;
            @include bg($bgcolor, 2);
            @extend %flex-c-c;
        }
    }
}
// how to use
// if need shape then add "shape" paramenter if not then null
// @include title-with-icon(shape, chart-bar-square, 18, neutral-0);
// title with icon ends
@mixin tag-config-1($type: null, $format: null, $color: null, $bg: null) {
    // text-color --> common style
    // color: var(--#{$color});
    //primary btn style starts
    @if $type==fill {
        background-color: var(--#{$bg});
        color: var(--#{$color});
        &:hover {
            background-color: var(--#{$bg});
        }
        &:focus {
            background-color: var(--#{$bg});
        }
    }
    @else if $type==outline {
        color: var(--#{$color});
        border-color: var(--#{$bg});
        &:hover {
            background-color: var(--neutral-50);
        }
        &:focus {
            background-color: var(--neutral-50);
            border-color: var(--specific-selected-0);
        }
    }
}
// Default project wrapper
@mixin default-wrapper() {
    max-width: var(--container-max-width);
    width: 100%;
    margin-inline: auto;
}
// Large project wrapper. Uses half container-white-space
@mixin large-wrapper() {
    max-width: var(--lg-container-max-width);
    width: 100%;
    margin-inline: auto;
}
// Search Results page 'Documents', 'Web Pages' & 'Federations' styles
@mixin search-page-common() {
    .documents,
    .web-pages,
    .federation {
        .article {
            &-list {
                gap: var(--space-4);
                overflow-x: auto;
                @extend %flex;
            }
            &-item {
                max-width: 33rem;
                width: calc(100% - var(--space-6));
                height: 28.6rem;
                border: .1rem solid var(--neutral-20);
                border-radius: 1.6rem;
                flex-shrink: 0;
                @extend %p-6-4;
                @extend %relative;
            }
            &-title {
                word-break: break-word;
                @include truncate-text(3, 150);
                @extend %mb-5;
                @extend %neutral-100;
                @extend %title-40;
                &::before {
                    color: var(--neutral-40);
                }
            }
        }
        .meta {
            @extend %neutral-40;
            &-date::after {
                content: "\00B7";
                @extend %mx-2;
            }
            &-label {
                @include position(null, null, var(--space-6), var(--space-4));
                @extend %neutral-70;
                @extend %text-lb;
            }
        }
    }
    .documents .article-title {
        @include title-with-icon(shape, document, 20);
    }
    .web-pages .article-title {
        @include title-with-icon(shape, globe, 20);
    }
    .federation {
        .article-title {
            @include title-with-icon(shape, flag, 20);
        }
        .article-origin {
            gap: var(--space-2);
            @extend %flex-n-c;
            img {
                width: 2.4rem;
                min-width: 2.4rem;
                aspect-ratio: 1;
                @extend %circle-radius;
            }
            .text {
                @extend %neutral-40;
            }
        }
    }
    @include mq(col-tablet) {
        .documents,
        .web-pages,
        .federation {
            .article {
                &-list {
                    gap: var(--space-6);
                    flex-wrap: wrap;
                    @include card-count(3, var(--space-4));
                }
                &-item {
                    max-width: unset;
                }
            }
        }
    }
    @include mq(col-desktop) {
        .documents,
        .web-pages,
        .federation {
            .article-list {
                @include card-count(4, var(--space-6));
            }
        }
    }
}
// Tabs common styles for 'Where to watch' & 'Five Star Rankings'
@mixin tabs() {
    .tabs {
        overflow: auto;
        @extend %flex;
        @extend %mx-4;
        @extend %gap-2;
        .tab {
            &-item {
                width: 100%;
                min-width: max-content;
                cursor: pointer;
                opacity: .6;
                @extend %px-5;
                @extend %py-4;
                @extend %text-center;
                &.active {
                    opacity: 1;
                    @include border(3, primary-70, 10, bottom);
                    .tab-text {
                        color: inherit;
                    }
                }
            }
            &-text {
                @extend %neutral-100;
                @extend %text-m;
            }
        }
    }
}
@mixin backdrop-blur($blur) {
    -webkit-backdrop-filter: blur($blur);
    backdrop-filter: blur($blur);
}
// @include backdrop-blur(1rem)